/**
 * Renders a Heading with a Description and a Button
 * optional props are colors for heading and description
 */

import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import Button from '../../atoms/Buttons/Button/Button';
import { Heading2 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import {
  breakpoints,
  buttonSpacing,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  headingColors,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import sanityImage from '../../../utils/sanityImage';
import Image from '../../atoms/Image/Image';
import VideoButton from '../../atoms/Buttons/VideoButton/VideoButton';

const TextBlock = ({
  align,
  button,
  className,
  description,
  descriptionColor,
  DescriptionType,
  headingText,
  headingTextColor,
  HeadingType,
  icon,
  preTitle,
}) => {
  const backgroundColor =
    headingTextColor === 'white' ? colors.darkGray.three : colors.lightGray.six;

  // apply css
  const textBlockCss = css`
    display: block;
    align-items: ${align === 'left' ? 'baseline' : 'center'};
    margin: ${uc('0 auto 20px')};
    text-align: ${align};

    @media (${breakpoints.largeTablet}) {
      padding: ${uc('20px')};
      background-color: ${backgroundColor};
      border-radius: ${uc('20px')};
    }

    @media (${breakpoints.mobile}) {
      margin: ${uc('0 auto')};
    }

    ${HeadingType} {
      display: ${headingText === '' ? 'none' : 'block'};
      color: ${headingColors[headingTextColor]};
      font-weight: ${fontWeights.bold};
      font-size: ${fontSizes.thirty};
      line-height: ${lineHeights.title};

      @media (${breakpoints.largeTablet}) {
        font-size: ${fontSizes.twentyFive};
      }
    }

    ${DescriptionType} {
      color: ${headingColors[descriptionColor]};
      font-size: ${fontSizes.sixteen};
      font-family: ${fontFamilies.roboto};
      line-height: ${lineHeights.copy};
    }

    .pre-title {
      font-weight: ${fontWeights.medium};
      font-size: ${fontSizes.sixteen};
      font-family: ${fontFamilies.roboto};
      line-height: ${lineHeights.title};
      letter-spacing: ${uc('0.5px')};

      @media (${breakpoints.mobile}) {
        margin-bottom: 0;
      }
    }

    .buttons {
      display: flex;
      justify-content: ${align === 'center' ? 'center' : 'inherit'};

      @media (${breakpoints.mobile}) {
        flex-wrap: wrap;
      }

      button,
      a {
        ${buttonSpacing}
      }

      @media (${breakpoints.mobile}) {
        display: block;
        width: 100%;

        button,
        a {
          display: block;
          width: 100%;
          margin-right: 0;
          text-align: left;
        }
      }
    }
  `;

  const imageCss = css`
    align-self: initial;
    margin-bottom: ${uc('30px')};
  `;

  /**
   * Render buttons for text block
   * @return {*}
   */
  const renderButtons = () => (
    <div className="buttons">
      {button.map(btn => {
        const buttonElement =
          btn._type === 'videoButton' ? (
            <VideoButton key={btn._key} className="video-button" {...btn}>
              {btn.buttonText}
            </VideoButton>
          ) : (
            <Button key={btn._key} {...btn}>
              {btn.text}
            </Button>
          );

        return buttonElement;
      })}
    </div>
  );

  /**
   * Render text block.
   */
  return (
    <div css={textBlockCss} className={className}>
      {sanityImage(icon).url() && (
        <Image
          css={imageCss}
          src={sanityImage(icon)
            .auto('format')
            .maxHeight(100)
            .url()}
          alt={icon.alt}
        />
      )}
      {preTitle.length > 0 && <h4 className="pre-title">{preTitle}</h4>}
      {headingText.length > 0 && <HeadingType>{headingText}</HeadingType>}
      {description.length > 0 && (
        <DescriptionType>{description}</DescriptionType>
      )}
      {button.length > 0 ? renderButtons() : null}
    </div>
  );
};

/**
 * Prop types
 */
TextBlock.propTypes = {
  align: PropTypes.string,
  button: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  DescriptionType: PropTypes.shape({}),
  headingText: PropTypes.string,
  headingTextColor: PropTypes.string,
  HeadingType: PropTypes.shape({}),
  icon: PropTypes.shape({ alt: PropTypes.string }),
  preTitle: PropTypes.string,
};

/**
 * Default props
 */
TextBlock.defaultProps = {
  align: 'inherit',
  button: {},
  className: 'textBlockCss',
  description: '',
  descriptionColor: colors.darkGray.one,
  DescriptionType: Text,
  headingText: '',
  headingTextColor: colors.darkGray.three,
  HeadingType: Heading2,
  icon: {},
  preTitle: '',
};

export default TextBlock;
